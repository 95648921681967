import { fromJS, Map } from "immutable";

import { getEnvironmentDescriptionId } from "Libs/utils";
import logger from "Libs/logger";

const LOAD_VARIABLES_START = "app/variables/load_variables_start";
const LOAD_VARIABLES_SUCCESS = "app/variables/load_variables_success";
const LOAD_VARIABLES_FAILURE = "app/variables/load_variables_failure";

const UPDATE_VARIABLE_START = "app/variable/update_variable_start";
const UPDATE_VARIABLE_SUCCESS = "app/variable/update_variable_success";
const UPDATE_VARIABLE_FAILURE = "app/variable/update_variable_failure";
const UPDATE_VARIABLE_CANCEL = "app/variable/update_variable_cancel";

const DELETE_VARIABLE_START = "app/variable/delete_variable_start";
const DELETE_VARIABLE_SUCCESS = "app/variable/delete_variable_success";
const DELETE_VARIABLE_FAILURE = "app/variable/delete_variable_failure";

const EDIT_LINE = "app/variable/edit_line";

export const editLine = (index, isNew) => ({
  type: EDIT_LINE,
  payload: index,
  meta: { isNew }
});

export const cancelUpdateVariable = () => ({ type: UPDATE_VARIABLE_CANCEL });

export const deleteVariable = (
  organizationDescriptionId,
  projectDescriptionId,
  environmentDescriptionId,
  variable
) => {
  return async dispatch => {
    if (!variable) {
      return false;
    }

    dispatch({ type: DELETE_VARIABLE_START });

    try {
      await variable.delete();

      dispatch({
        type: DELETE_VARIABLE_SUCCESS,
        payload: variable,
        meta: {
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId
        }
      });
    } catch (err) {
      logger(err, {
        action: "environment_delete_variable",
        meta: {
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId
        },
        payload: variable
      });
      dispatch({
        type: DELETE_VARIABLE_FAILURE,
        payload: err.detail || err,
        error: true
      });
    }
  };
};

export const loadVariables = (
  organizationDescriptionId,
  projectDescriptionId,
  environmentDescriptionId,
  environment
) => {
  return async dispatch => {
    if (!environment) {
      return false;
    }

    dispatch({ type: LOAD_VARIABLES_START });

    try {
      const variables = await environment.getVariables();

      dispatch({
        type: LOAD_VARIABLES_SUCCESS,
        payload: {
          variables
        },
        meta: {
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId
        }
      });
    } catch (err) {
      logger(err, {
        action: "environment_load_variable",
        meta: {
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId
        }
      });
      dispatch({ type: LOAD_VARIABLES_FAILURE, error: true, payload: err });
    }
  };
};

export const updateVariable = (
  organizationDescriptionId,
  projectDescriptionId,
  environment,
  variable,
  editedLine,
  isNew
) => {
  return async (dispatch, getState) => {
    if (!environment) {
      return false;
    }
    dispatch({ type: UPDATE_VARIABLE_START });

    try {
      const result = await environment.setVariable(
        variable.name,
        variable.value,
        variable.is_json,
        variable.is_enabled,
        variable.is_inheritable,
        variable.is_sensitive
      );

      if ((result.data && result.data.code / 100) === 4) {
        return dispatch({
          type: UPDATE_VARIABLE_FAILURE,
          payload: result.detail,
          meta: { editedLine, isNew }
        });
      }

      const newVariable = await result.getEntity();

      dispatch({
        type: UPDATE_VARIABLE_SUCCESS,
        payload: newVariable,
        meta: {
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId: getEnvironmentDescriptionId(
            getState,
            organizationDescriptionId,
            projectDescriptionId,
            environment.id
          )
        }
      });
    } catch (err) {
      logger(err, {
        action: "environment_load_variable",
        meta: {
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId: getEnvironmentDescriptionId(
            getState,
            organizationDescriptionId,
            projectDescriptionId,
            environment.id
          )
        },
        environment,
        variable,
        editedLine,
        isNew
      });
      dispatch({
        type: UPDATE_VARIABLE_FAILURE,
        payload: err.detail,
        meta: { editedLine, isNew }
      });
    }
  };
};

export default function environmentVariableReducer(state = new Map(), action) {
  switch (action.type) {
    case LOAD_VARIABLES_START:
      return state.set("loading", true);
    case EDIT_LINE:
      return state
        .set("editedLine", action.payload)
        .set("isNew", action.meta.isNew);
    case UPDATE_VARIABLE_CANCEL:
      return state.set("editedLine", false).set("isNew", false);
    case DELETE_VARIABLE_SUCCESS:
      return state.deleteIn([
        "data",
        action.meta.organizationDescriptionId,
        action.meta.projectDescriptionId,
        action.meta.environmentDescriptionId,
        action.payload.id
      ]);
    case UPDATE_VARIABLE_START:
      return state.set("errors", new Map()).set("updateLoading", true);
    case UPDATE_VARIABLE_SUCCESS:
      return state
        .setIn(
          [
            "data",
            action.meta.organizationDescriptionId,
            action.meta.projectDescriptionId,
            action.meta.environmentDescriptionId,
            action.payload.id
          ],
          fromJS(action.payload)
        )
        .set("editedLine", false)
        .set("isNew", false)
        .set("updateLoading", false);
    case LOAD_VARIABLES_SUCCESS:
      return state.set("loading", false).set(
        "data",
        fromJS(
          action.payload.variables.reduce(
            (organizationsProjectsEnvironements, variable) => {
              if (
                !organizationsProjectsEnvironements[
                  action.meta.organizationDescriptionId
                ]
              ) {
                organizationsProjectsEnvironements[
                  action.meta.organizationDescriptionId
                ] = {};
              }
              if (
                !organizationsProjectsEnvironements[
                  action.meta.organizationDescriptionId
                ][action.meta.projectDescriptionId]
              ) {
                organizationsProjectsEnvironements[
                  action.meta.organizationDescriptionId
                ][action.meta.projectDescriptionId] = {};
              }
              if (
                !organizationsProjectsEnvironements[
                  action.meta.organizationDescriptionId
                ][action.meta.projectDescriptionId][
                  action.meta.environmentDescriptionId
                ]
              ) {
                organizationsProjectsEnvironements[
                  action.meta.organizationDescriptionId
                ][action.meta.projectDescriptionId][
                  action.meta.environmentDescriptionId
                ] = {};
              }
              organizationsProjectsEnvironements[
                action.meta.organizationDescriptionId
              ][action.meta.projectDescriptionId][
                action.meta.environmentDescriptionId
              ][variable.id] = variable;

              return organizationsProjectsEnvironements;
            },
            {}
          )
        )
      );
    case LOAD_VARIABLES_FAILURE:
      return state.set("loading", false).set("errors", fromJS(action.payload));
    case UPDATE_VARIABLE_FAILURE:
      return state
        .set("updateLoading", false)
        .set("errors", fromJS(action.payload));
    default:
      return state;
  }
}
